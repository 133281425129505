@import "https://fonts.cdnfonts.com/css/neue-haas-grotesk-text";
body {
  height: 100vh;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  background-color: #e9e9e9;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  font-family: Neue Haas Grotesk Text, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  display: flex;
  position: fixed;
}

.app {
  justify-content: center;
  display: flex;
}

.header {
  width: -webkit-fill-available;
  color: #eee;
  z-index: 100;
  background-color: #333;
  justify-content: center;
  place-items: center;
  margin: 0;
  font-size: xx-small;
  display: flex;
  position: fixed;
  top: 0;
}

.header .logo {
  margin: 12px 12px 12px -14px;
}

input {
  width: 100%;
  color: #333;
  text-align: center;
  font: inherit;
  z-index: 1;
  background-color: #f5f5f5;
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  font-size: x-large;
  font-weight: 500;
  transition: all .5s ease-in;
  position: absolute;
  top: 0;
  left: 0;
}

.has-text {
  border-radius: 0;
}

.unselectable {
  -webkit-user-select: none;
  user-select: none;
}

.link {
  cursor: pointer;
}

input::placeholder {
  color: #aaa;
  opacity: 1;
}

.load-animation {
  -webkit-background-size: 800px 104px;
  background: linear-gradient(to right, #ddd 8%, #ccc 18%, #ddd 33%);
  border-color: #ddd;
  transition: none;
  animation-name: flash;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

@media (width <= 768px) {
  .load-animation {
    -webkit-background-size: 400px 104px;
    animation-duration: 6s;
  }
}

@keyframes flash {
  0% {
    background-position: -936px 0;
  }

  100% {
    background-position: 936px 0;
  }
}

input:focus {
  border-color: #b8b8b8;
  border-radius: 0;
  outline: none;
  transition: all .5s;
}

.footer {
  color: gray;
  width: auto;
  align-items: flex-end;
  margin: 50% 20px 20px;
  font-size: smaller;
  position: absolute;
  bottom: 0;
}

.search-container {
  width: 70vw;
  max-width: 800px;
  font-family: Neue Haas Grotesk Text, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: x-large;
  font-weight: 500;
  position: relative;
  left: -10px;
}

.searchbar {
  justify-content: center;
  display: flex;
}

.dropdown {
  color: #eee;
  width: 100%;
  perspective: 1000px;
  z-index: 2;
  font-size: x-large;
  font: inherit;
  background: #bbb;
  border: 2px solid #ddd;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.dropdown:hover, .emulated-hover {
  cursor: pointer;
  background: #888;
  border-radius: 0;
  transition: all .5s ease-in;
}

.emulated-hover .dropdown_menu li, .dropdown:hover .dropdown_menu li {
  display: block;
}

.dropdown_menu {
  width: 100%;
  perspective: 1000px;
  z-index: -1;
  padding: initial;
  margin: auto;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown_menu li {
  color: #888;
  opacity: 0;
  text-align: center;
  background-color: #e3e3e3;
  padding: 10px 20px;
  font-size: x-large;
  display: none;
}

.dropdown_menu li:hover {
  color: #eee;
  background-color: #aaa;
}

.dropdown:hover .dropdown_menu--animated {
  display: block;
}

.dropdown_menu--animated {
  display: none;
}

.dropdown_menu--animated li {
  opacity: 1;
  display: block;
}

.dropdown_menu-2 .dropdown_item-1 {
  transform-origin: top;
  animation: .3s ease-in-out 60ms forwards rotateX;
}

.dropdown_menu-2 .dropdown_item-2 {
  transform-origin: top;
  animation: .3s ease-in-out .12s forwards rotateX;
}

.dropdown_menu-2 .dropdown_item-3 {
  transform-origin: top;
  animation: .3s ease-in-out .18s forwards rotateX;
}

.dropdown_menu-2 .dropdown_item-4 {
  transform-origin: top;
  animation: .3s ease-in-out .24s forwards rotateX;
}

.dropdown_menu-2 .dropdown_item-5 {
  transform-origin: top;
  animation: .3s ease-in-out .3s forwards rotateX;
}

@keyframes rotateX {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }

  50% {
    transform: rotateX(-20deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}

/*# sourceMappingURL=index.a525b424.css.map */
